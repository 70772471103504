import Image from "next/image";
import Link from 'next/link'
import format from 'date-fns/format'

export default function BlogContent({ articles }) {
  return (
    <section className="relative bg-gray-50 py-12">
      <h4 className="text-2xl lg:text-4xl text-gray-900 mb-8 font-serif font-medium text-center font-medium">
        Insights from the team
      </h4>
      <div className="mt-12 max-w-2xl mx-auto grid gap-8 sm:grid-cols-3 px-6 lg:grid-cols-3 md:max-w-4xl lg:max-w-7xl lg:mx-auto lg:px-8">
        {articles.map((article) => (
          <div
            className="relative flex flex-col rounded-lg shadow-lg overflow-hidden"
            key={article.id}
          >
            <Link href={article.metadata.path} legacyBehavior>
              <>
                {article.image ? (
                  <div className="relative h-48 flex-shrink-0">
                    <Image
                      src={article.image.url}
                      className="h-48 w-full object-cover"
                      alt={article.title}
                      fill
                      sizes="100vw" />
                  </div>
                ) : null}
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="font-medium text-brand-600">Article</p>
                    <div className="block mt-2">
                      <p className="text-xl lg:text-3xl font-serif font-medium text-gray-900">
                        {article.title}
                      </p>
                    </div>
                  </div>
                  <div className="mt-6 flex items-center">
                    <div>
                      <div className="flex space-x-1 text-sm text-gray-500">
                        <time dateTime="2020-03-16">
                          Published:{' '}
                          {format(new Date(article.updatedAt), 'dd/MM/yyyy')}
                        </time>
                      </div>
                    </div>
                  </div>
                </div>
                <a href={article.metadata.path} className="stretch-link">
                  <span className="sr-only">
                  {article.title} - {article.metadata.description}
                  </span>
                </a>
              </>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
}
